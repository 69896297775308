<template>
  <div>
    <div class="theme-light text-left pb-1">{{ label }}</div>
    <div v-if="errors && errors.length" class="error-msg text-left v-messages pb-1">
      {{ errors[0] }}
    </div>
    <div :class="[fullScreenMode ? 'json-wrap-fullscreen' : 'json-wrap']">
      <template v-if="fullScreenMode">
        <v-card-actions class="action-wrap">
          <v-btn @click="save" color="primary" text class="my-0" :disabled="!!errors.length">
            {{ customProps && customProps.saveText ? customProps.saveText : 'сохранить' }}
          </v-btn>
          <v-btn @click="close" color="primary" text class="my-0">
            {{ customProps && customProps.closeText ? customProps.closeText : 'закрыть' }}
          </v-btn>
        </v-card-actions>
        <v-divider />
      </template>

      <ace-editor
        v-model="jsonData"
        @init="editorInit"
        :lang="'json'"
        theme="monokai"
        width="100%"
        height="100%"
        ref="aceEditor" />

      <v-icon
        v-if="fullScreenAvailable && !fullScreenMode"
        class="full-screen-btn"
        :size="2"
        @click="toggleFullScreen"
        color="grey"
        small
        >fa fa-expand</v-icon
      >
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import beautify from 'js-beautify';

  export default {
    name: 'form-code-json',

    components: {
      'ace-editor': require('vue2-ace-editor'),
    },

    props: {
      value: [Array, Object, String, Number],
      customProps: {
        type: Object,
      },
      label: String,
      rules: {
        type: Array,
        default: () => [],
      },
      required: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        editor: null,
        fullScreenMode: false,
        height: '100%',
        errors: [],
        jsonDataInFullScreenEditor: '',
      };
    },

    computed: {
      jsonDataMainForm: {
        get() {
          let res = this.value || '';

          if (this.value && typeof this.value !== 'string') {
            try {
              res = JSON.stringify(this.value, null, 4);
            } catch (error) {
              console.log('FormCode (line : 27) | computed:jsonData | error : ', error);
              res = `${res}`;
            }
          }
          return beautify(res, this.beautifyConfig);
        },
        set(val) {
          this.validate(this.rules, val);

          if (this.errors.length) return;

          const json = JSON.parse(val);
          this.$emit('input', json);
        },
      },
      jsonDataFullScreen: {
        get() {
          if (this.errors.length) return this.jsonDataInFullScreenEditor;
          return beautify(this.jsonDataInFullScreenEditor, this.beautifyConfig);
        },
        set(val) {
          this.validate(this.rules, val);
          let res = val;
          if (typeof val !== 'string') {
            try {
              res = JSON.stringify(val, null, 4);
            } catch (error) {
              console.log('FormCode (line : 27) | computed:jsonData | error : ', error);
              res = `${res}`;
            }
          }
          this.jsonDataInFullScreenEditor = res;
        },
      },
      jsonData: {
        get() {
          if (!this.fullScreenMode) return this.jsonDataMainForm;
          return this.jsonDataFullScreen;
        },
        set(v) {
          if (!this.fullScreenMode) this.jsonDataMainForm = v;
          if (this.fullScreenMode) this.jsonDataFullScreen = v;
        },
      },
      fullScreenAvailable() {
        if (this.customProps && 'isFullScreenAvailable' in this.customProps) {
          return !!this.customProps.isFullScreenAvailable;
        }
        return false;
      },
      beautifyConfig() {
        if (this.customProps && 'beautifyOptions' in this.customProps) {
          return this.customProps.beautifyOptions;
        }
        return {};
      },
    },
    methods: {
      async validate(rules, value) {
        this.errors = [];

        try {
          JSON.parse(value);
        } catch (e) {
          this.errors.push('Invalid JSON');
        }

        await Promise.all(
          _.map(rules, async validator => {
            const result = await validator(value);
            if (result !== true) this.errors.push(result);
          })
        );

        const isValid = this.errors.length === 0;
        this.$emit('valid', isValid);
      },

      editorInit() {
        require('brace/mode/json');
        require('brace/mode/javascript');
        require('brace/theme/monokai');
        require('brace/snippets/json');
        require('brace/snippets/javascript');
      },

      save() {
        this.jsonDataMainForm = this.jsonDataFullScreen;
        this.toggleFullScreen();
      },
      close() {
        this.$emit('input', this.jsonDataMainForm);
        this.toggleFullScreen();
      },
      async toggleFullScreen() {
        if (!this.editor) return;
        this.fullScreenMode = !this.fullScreenMode;

        if (this.fullScreenMode) this.jsonDataFullScreen = this.value;

        await Promise.resolve();

        this.editor.getSession().getScreenLength() *
          (this.editor.renderer.lineHeight + this.editor.renderer.scrollBar.getWidth());
        this.editor.container.style.height = `100%`;

        this.editor.resize();

        if (this.errors.length) {
          this.editor.setValue(this.jsonData);
        }
      },
    },

    created() {
      if (this.rules && this.rules.length) this.validate(this.rules, this.jsonData);
    },
    mounted() {
      this.editor = this.$refs.aceEditor.editor;
    },
  };
</script>

<style scoped>
  .error-msg {
    color: #ff5252 !important;
    caret-color: #ff5252 !important;
  }

  .theme-light {
    color: rgba(0, 0, 0, 0.54);
  }

  .json-wrap {
    position: relative;
    height: 200px;
  }
  .json-wrap-fullscreen {
    z-index: 9999;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100vh;
  }

  .full-screen-btn {
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 10000;
    background-color: #ffff;
    width: 23px;
    height: 23px;
    display: inline-block;
    font-size: 13px !important;
    border-radius: 50%;
  }

  .full-screen-btn:hover {
    cursor: pointer;
  }

  .action-wrap {
    background-color: #ffffff;
    justify-content: end;
  }
</style>
